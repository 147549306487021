import { ReactNode, useEffect, useState } from "react";
import {
  LandingPageAssetPath,
  getLandingPageAssetPath,
} from "../../../../../types/common/assets";
import { LandingAppendTriangle } from "../../../common/landing-append-triangle";

// TODO: Potentially refactor directions into types where landing paths are (since they are commonly referenced)
/**
 * Component representing a feature box that can be attached relative to a demo page component
 */
export function LandingFeatureBox({
  heading,
  content,
  imagePath,
  backgroundColor: { tailwind: tailwindBackground, rgb } = {
    tailwind: "bg-loophole-blue-purple",
    rgb: [85, 91, 230],
  },
  logoElement,
  alignVisual = "LEFT",
  addTriangle = true,
}: {
  heading: string;
  content: string;
  addTriangle?: boolean;
  backgroundColor?: { tailwind: string; rgb: [number, number, number] };
  imagePath?: LandingPageAssetPath;
  logoElement?: ReactNode;
  alignVisual?: "LEFT" | "RIGHT";
}) {
  const [triangleDimensions, setTriangleDimensions] = useState({
    triangleWidth: 10,
    triangleHeight: 20,
  });

  // Function to update triangle dimensions on window resize
  const handleResize = () => {
    setTriangleDimensions({
      triangleWidth: 10,
      triangleHeight: window.innerWidth < 640 ? 5 : 20,
    });
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only on mount and unmount

  const featureImageSrc = imagePath ? getLandingPageAssetPath(imagePath) : null;
  const featureImage = featureImageSrc && (
    <img
      src={featureImageSrc}
      alt={heading}
      className="w-[80px] h-[80px]"
    ></img>
  );

  const featureVisual = (
    <div className="flex flex-col items-center align-middle justify-center px-[10px]">
      {logoElement ? (
        <div className="flex w-[40px] h-[40px] items-center justify-center align-middle ">
          {logoElement}
        </div>
      ) : (
        featureImage
      )}
    </div>
  );

  const featureBoxRow = (
    <div className={`flex flex-row justify-between`}>
      {alignVisual === "LEFT" && featureVisual}

      <div className="flex flex-col text-white px-[10px] font-raleway">
        <div className="text-[15px] font-bold">{heading}</div>
        <div className="text-[12px] text-opacity-80 text-white font-semibold">
          {content}
        </div>
      </div>

      {alignVisual === "RIGHT" && featureVisual}
    </div>
  );

  // TODO: Need to fix left or right align (text and logo depending on alignVisual)
  // TODO: Make text size smaller
  // Does not care whether to align left or right
  const featureBoxCol = (
    <div className={`flex flex-col`}>
      {featureVisual}

      <div className="flex flex-col text-white px-[10px] font-raleway">
        <div className="text-[12px] text-center sm:text-[15px] font-bold">
          {heading}
        </div>
        <div className="hidden sm:block text-[11px] text-opacity-80 text-white font-semibold text-center">
          {content}
        </div>
      </div>
    </div>
  );

  // Determine when to use row or col
  const featureBox = (
    // TODO: Change border color to be taken as color parameter OR remove
    <div
      className={`flex justify-center rounded-lg shadow-2xl ${tailwindBackground} bg-opacity-80 p-[10px]`}
    >
      <div className="hidden md:block lg:hidden xl:block">{featureBoxRow}</div>
      <div className="block md:hidden lg:block xl:hidden">{featureBoxCol}</div>
    </div>
  );

  return !addTriangle ? (
    featureBox
  ) : (
    <LandingAppendTriangle
      parent={featureBox}
      dimensions={triangleDimensions}
      direction={alignVisual}
      cssBackgroundColor={`rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.8)`}
    />
  );
}

export type DemoFeatureBox = {
  content: any;
  featureBoxTailwind: string;
};

/**
 * Returns a demo page component, which includes the demo page image and feature boxes
 */
export function DemoPage({
  featureBoxes,
  imageTailwind,
  pageAltName,
  demoPageImagePath,
}: {
  imageTailwind?: string;
  pageAltName: string;
  demoPageImagePath: LandingPageAssetPath;
  featureBoxes: DemoFeatureBox[];
}) {
  return (
    <div className="relative">
      <img
        src={getLandingPageAssetPath(demoPageImagePath)}
        alt={pageAltName}
        className={`${imageTailwind}`}
      ></img>

      {
        // Feature boxes
        featureBoxes.map(({ content, featureBoxTailwind }, index) => (
          // Injecting tailwind utility classes
          <div key={index} className={`absolute ${featureBoxTailwind} `}>
            {content}
          </div>
        ))
      }
    </div>
  );
}
