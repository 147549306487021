import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { seoStructuredData } from "./seo-scripts/seo-structured-data";

// TODO: Uses handle contacts in a similar way that landing-email-sign-up does
export function LandingContact() {
  const helmet = (
    <Helmet>
      <title>Contact Us | Loophole</title>
      <meta
        name="description"
        content="Reach out to our team for any feedback or questions."
      />
      <script type="application/ld+json">
        {JSON.stringify(seoStructuredData)}
      </script>
    </Helmet>
  );

  // TODO: Remove relocation when making custom web page
  // Redirect to a different website
  useEffect(() => {
    window.location.href =
      "https://docs.google.com/forms/d/e/1FAIpQLSdHfCvH-VON54g4GpI4g8a7P2XMOjXi6ZX0vXTBQoiSgOZHKA/viewform?usp=sf_link";
  }, []);

  return <>{helmet}</>;
}
