/**
 * The base path of the landing page assets, following
 * `<rootDir>/public`).
 */
const LANDING_PAGE_BASE_PATH = "assets/landing-assets";

/**
 * Constructs path to landing-page asset, following `<rootDir>/public`.
 */
export function getLandingPageAssetPath(
  assetPath: LandingPageAssetPath
): string {
  return `${LANDING_PAGE_BASE_PATH}/${assetPath}`;
}

/**
 * The enumeration of the paths to the landing page assets.
 * Located: `<rootDir>/public/assets/landing-assets`.
 */
export enum LandingPageAssetPath {
  LoopholeLogoTransparent = "loophole-logo-transparent.png",
  FeaturedPagesLoopholeSpecific = "loophole-specific-page-demo.png",
  AppStoreDownload = "app-store-download.png",
}
