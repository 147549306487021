import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { seoStructuredData } from "./seo-scripts/seo-structured-data";

export function LandingCommunity() {
  const helmet = (
    <Helmet>
      <title>Community Policy | Loophole</title>
      <meta name="description" content="Loophole Platforms Community Policy" />
      <script type="application/ld+json">
        {JSON.stringify(seoStructuredData)}
      </script>
    </Helmet>
  );

  // TODO: Remove relocation when making custom web page
  // Redirect to a different website
  useEffect(() => {
    window.location.href =
      "https://loopholeplatforms.notion.site/Loophole-Platforms-Community-Guidlines-cdb06dc21aec415795bcb6fd912dbd49?pvs=74";
  }, []);

  return <>{helmet}</>;
}
