/**
 * The enumeration of the page routes in the app.
 */
export enum LoopholeBrowserRoute {
  LandingHome = "/",
  LandingAbout = "/about",
  LandingContact = "/contact",
  LandingFAQ = "/about/#faq",
  LandingSafety = "/safety",
  LandingCommunity = "/community",
  LandingCookie = "/cookie",
  LandingPrivacy = "/privacy",
  LandingTerms = "/terms",
}
