import { useState } from "react";
import { VscTriangleUp } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { RouteChangeTracker } from "../../../../libs/loophole-apis/src/logging";
import { LoopholeBrowserRoute } from "../../../types/routes/main-routes";
import { LandingAppendTriangle } from "./landing-append-triangle";
import {
  LandingSocialLink,
  createLandingSocialLinks,
} from "./landing-social-link";

interface FooterLinkProps {
  linkTo: LoopholeBrowserRoute;
  displayText: string;
}

/**
 * Creates and returns footer link component with hover effect and onClick route logic.
 *
 * @param linkTo Browser route to which the link points
 * @param displayText Link display text
 * @returns Link component
 */
const FooterLink: React.FC<FooterLinkProps> = (props) => (
  <Link
    to={props.linkTo}
    className="flex text-sm items-center hover:text-white"
  >
    {props.displayText}
  </Link>
);

interface FooterMenuProps {
  footerLinksProps: FooterLinkProps[];
  displayText: string;
  itemDirection: "UP" | "DOWN";
}

/**
 * Creates and returns drop down (or up) menu component with footer links as menu items
 *
 * @param footerLinksProps Footer link props that drop down menu should open to
 * @param displayText Display text of the drop down menu
 * @param itemDirection Direction in which to display the footer links
 * @returns Menu component
 */
// TODO: Fix so we don't have to use custom width and instead uses max width instead
const FooterMenu: React.FC<FooterMenuProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const menuLinks = (
    <div className="flex flex-col py-1 rounded-xl bg-opacity-80 bg-loophole-blue-purple">
      {props.footerLinksProps.map((linkProps, index) => (
        <div className="flex w-[95px] h-[40px] mx-6" key={index}>
          <FooterLink {...linkProps} />
        </div>
      ))}
    </div>
  );

  const menuLinksContainer = (
    <div className="absolute bottom-[20px] left-1/2 transform -translate-x-[48px] sm:-translate-x-1/2">
      <LandingAppendTriangle
        parent={menuLinks}
        direction="DOWN"
        dimensions={{ triangleWidth: 10, triangleHeight: 6 }}
        cssBackgroundColor="rgba(85, 91, 230, 0.8)"
      />
    </div>
  );

  return (
    <>
      <div
        className="relative"
        onMouseEnter={() => {
          setShowMenu(true);
        }}
        onMouseLeave={() => {
          setShowMenu(false);
        }}
        // Mobile interaction
        onFocus={() => {
          setShowMenu(true);
        }}
        onBlur={() => setShowMenu(false)}
      >
        {showMenu && menuLinksContainer}

        <div className="flex flex-row justify-between align-middle items-center w-[65px] text-sm hover:text-white">
          <div onClick={(showMenu) => !showMenu}>{props.displayText}</div>
          <VscTriangleUp />
        </div>
      </div>
    </>
  );
};

export function LandingFooter() {
  RouteChangeTracker();

  const footerLinksProps: FooterLinkProps[] = [
    {
      linkTo: LoopholeBrowserRoute.LandingAbout,
      displayText: "About",
    },
    {
      linkTo: LoopholeBrowserRoute.LandingPrivacy,
      displayText: "Privacy Policy",
    },
    {
      linkTo: LoopholeBrowserRoute.LandingTerms,
      displayText: "Terms of Services",
    },
    {
      linkTo: LoopholeBrowserRoute.LandingCommunity,
      displayText: "Community Guidelines",
    },
  ];

  // TODO: Figure out how to work in FAQ
  const supportLinksProps: FooterLinkProps[] = [
    { linkTo: LoopholeBrowserRoute.LandingContact, displayText: "Contact Us" },
    {
      linkTo: LoopholeBrowserRoute.LandingSafety,
      displayText: "Safety Center",
    },
    {
      linkTo: LoopholeBrowserRoute.LandingCookie,
      displayText: "Cookies Policy",
    },
  ];

  const socialLinksProps = createLandingSocialLinks(
    "text-[25px] sm:text-[18px] hover:text-white"
  );

  const copyrightCompany = (
    <div className="flex flex-row items-center p-[10px] sm:py-[5px] w-fit">
      <div className="text-sm font-semibold">
        &copy; 2024 Loophole Platforms
      </div>
    </div>
  );

  const supportMenu = (
    <div className="p-[10px] sm:py-[5px] w-fit">
      <FooterMenu
        footerLinksProps={supportLinksProps}
        displayText="Support"
        itemDirection="UP"
      />
    </div>
  );

  const footerLinks = footerLinksProps.map((linkProps, index) => (
    <div key={index} className="p-[10px] sm:py-[5px] w-fit">
      <FooterLink {...linkProps} />
    </div>
  ));

  const socialLinks = (
    <div className="p-[10px] sm:py-[5px] w-fit flex flex-row gap-[40px] sm:gap-[15px]">
      {socialLinksProps.map((socialLinkProps, index) => (
        <div key={index}>{LandingSocialLink(socialLinkProps)}</div>
      ))}
    </div>
  );

  const divider = (
    <div className="hidden sm:block h-[30px] w-[1px] mx-[5px] bg-opacity-30 bg-white"></div>
  );

  return (
    <div className="flex justify-center w-screen">
      <div className="flex flex-col sm:flex-row sm:flex-wrap w-[95%] max-w-[900px] py-[20px] sm:py-[10px] sm:justify-center sm:align-middle sm:items-center text-white text-opacity-80">
        {copyrightCompany}

        {divider}

        {footerLinks}

        {supportMenu}

        {divider}

        {socialLinks}
      </div>
    </div>
  );
}
