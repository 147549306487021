export const seoStructuredData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Loophole",
  description:
    "Social media platform focused on bringing a fun, multiplayer experience through sharing videos with friends, family, and interest communities.",
  url: "https://loophole.lol",
  logo: "https://loophole.lol/assets/landing-assets/loophole-logo-transparent.png",
  image:
    "https://loophole.lol/assets/landing-assets/loophole-logo-transparent.png",
  foundingDate: "2023-12-02",
  email: "admin@loophole.lol",
  areaServed: "US",
  legalName: "Loophole Platforms, LLC",
  contactPoint: {
    "@type": "ContactPoint",
    telephone: "9293734172",
    contactType: "customer service",
    areaServed: "US",
    availableLanguage: "en",
  },
  sameAs: ["https://www.linkedin.com/company/loophole-platforms/"],
  brand: {
    "@type": "Brand",
    name: "Loophole",
    logo: "https://loophole.lol/assets/landing-assets/loophole-logo-transparent.png",
    url: "https://loophole.lol",
    // TODO: Add instagram, etc
    sameAs: ["https://www.linkedin.com/company/loophole-platforms/"],
  },
  services: [
    {
      "@type": "Service",
      name: "Social Media Platform",
      description:
        "Share short videos with friends and find interest communities on Loophole - Hop on the Loop",
      category: "Social Media",
      provider: {
        "@type": "Organization",
        name: "Loophole",
        url: "https://loophole.lol",
      },
    },
  ],
  socialMediaPlatform: "Your Social Media",
  interactionStatistic: {
    "@type": "InteractionCounter",
    interactionType: "http://schema.org/UserInteraction",
    userInteractionCount: 100, // TODO: Figure out what this is
  },
  keywords: [
    "Social Media",
    "User Engagement",
    "Content Sharing",
    "Digital Community",
    "Hop on the Loop",
    "Loophole",
    "Loophole Platforms",
    "Hop",
    "Loops",
    "Loop",
  ],
  applicationCategory: "Social",
  inLanguage: "en-US",
  operatingSystem: "iOS, Android",
  // TODO: Add
  //   "potentialAction": [
  //     {
  //       "@type": "Action",
  //       "name": "Create Account",
  //       "target": {
  //         "@type": "EntryPoint",
  //         "urlTemplate": "https://your-social-media-company.com/signup"
  //       }
  //     }],
};
