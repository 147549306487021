import { useState } from "react";
import { IoRadioButtonOff, IoRadioButtonOnOutline } from "react-icons/io5";
import { LoopholeFeedDemo } from "./demo-pages";

// TODO: Set timeout logic (initial longer animate wait time on component mount or when user clicks a specific element + shorter animation wait time by default)
/**
 * Carousel container component for animating and previewing demo pages
 */
export function LandingFeatures() {
  // [0, numPages)
  const [pageIndex, setPageIndex] = useState<number>(0);

  // TODO: Add another view (different channels)
  const featuredPages = [<LoopholeFeedDemo />];

  const _ADD_CAROUSEL = false;

  // TODO: Add carousel + control
  // TODO: Look for components that are ready made on MUI or Ant?
  const carouselControls = _ADD_CAROUSEL && (
    <div className="flex flex-row justify-center py-[20px] w-[300px] text-white">
      <div className="flex flex-row align-middle items-center">
        {featuredPages.map((_, index) => (
          <button
            key={index}
            className="px-[5px]"
            onClick={() => setPageIndex(index)}
          >
            {index === pageIndex ? (
              <IoRadioButtonOnOutline />
            ) : (
              <IoRadioButtonOff />
            )}
          </button>
        ))}
      </div>
    </div>
  );

  // TODO: Add animation
  return (
    <>
      <div className="flex flex-col justify-center align-middle items-center">
        <div
          className="duration-[0.3s]"
          style={{ transform: `translate:(-${pageIndex * 100})` }}
        >
          {/* {featuredPages} */}
          {featuredPages.map(
            (page, index) =>
              index === pageIndex && <div key={index}>{page}</div>
          )}
        </div>
        {carouselControls}
      </div>
    </>
  );
}
