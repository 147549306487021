import { Link } from "react-router-dom";
import {
    LandingPageAssetPath,
    getLandingPageAssetPath,
} from "../../../types/common/assets";

import { LoopholeBrowserRoute } from "../../../types/routes/main-routes";

// TODO: Finish in the future
function LanguageMenu() {
  //   return (
  //     <div className="flex flex-row text-sm justify-between align-middle items-center w-[40px] text-white text-opacity-80">
  //       EN
  //       <VscTriangleDown />
  //     </div>
  //   );

  return <div></div>;
}

export function LandingHeader() {
  return (
    <div className="flex flex-row text-white w-screen px-4 sm:px-10 pt-4 justify-between align-middle">
      <div className="flex align-middle">
        <Link to={LoopholeBrowserRoute.LandingHome}>
          <img
            src={LoopholeLogoTransparent}
            alt="Loophole Logo"
            className="w-[70px] h-[70px] xl:w-[70px] xl:h-[70px] 2xl:w-[80px] 2xl:h-[80px]"
          ></img>
        </Link>
        <div className="flex items-center font-raleway text-[50px] sm:text-5xl 2xl:text-6xl font-bold">
          Loophole
        </div>
      </div>
      <LanguageMenu />
    </div>
  );
}

const LoopholeLogoTransparent = getLandingPageAssetPath(
  LandingPageAssetPath.LoopholeLogoTransparent
);
