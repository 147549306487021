import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faDiscord,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { logLinkClick } from "../../../../libs/loophole-apis/src/logging";
import { LoopholeSocialRoute } from "../../../types/routes/external-routes";

/**
 * Factory for cresting social link props (the ones that exist on the landing page)
 *
 * @param pixelSize The size of the icons
 */
export function createLandingSocialLinks(
  tailwind: string
): LandingSocialLinksProps[] {
  return [
    {
      linkTo: LoopholeSocialRoute.Instagram,
      social: "Instagram",
      icon: faInstagram,
      tailwind,
    },
    {
      linkTo: LoopholeSocialRoute.Discord,
      social: "Discord",
      icon: faDiscord,
      tailwind,
    },
    {
      linkTo: LoopholeSocialRoute.Twitter,
      social: "Twitter/X",
      icon: faXTwitter,
      tailwind,
    },
  ];
}

export interface LandingSocialLinksProps {
  icon: IconProp;
  linkTo: LoopholeSocialRoute;
  social: string;
  tailwind: string;
}

/**
 * Creates and returns a clickable social media icon
 *
 * @Returns Social media icon
 */
export const LandingSocialLink: React.FC<LandingSocialLinksProps> = (props) => {
  const location = useLocation();

  return (
    <a
      href={props.linkTo}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => logLinkClick(props.social, location.pathname)}
    >
      <div className={props.tailwind}>
        <FontAwesomeIcon icon={props.icon} />
      </div>
    </a>
  );
};
