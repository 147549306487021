import { useEffect } from "react";

export function LandingCookies() {
  // TODO: Remove relocation when making custom web page
  // Redirect to a different website
  useEffect(() => {
    window.location.href =
      "https://loopholeplatforms.notion.site/Loophole-Platforms-Cookies-Policy-089f3fdc9cd143678a15051fb48391e2";
  }, []);

  return <></>;
}
