import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { seoStructuredData } from "./seo-scripts/seo-structured-data";

export function LandingPrivacy() {
  const helmet = (
    <Helmet>
      <title>Privacy Privacy | Loophole</title>
      <meta name="description" content="Loophole Platforms Privacy Policy" />
      <script type="application/ld+json">
        {JSON.stringify(seoStructuredData)}
      </script>
    </Helmet>
  );
  // TODO: Remove relocation when making custom web page
  // Redirect to a different website
  useEffect(() => {
    window.location.href =
      "https://loopholeplatforms.notion.site/Loophole-Platforms-Privacy-Policy-e6054169a7254c46868bb6cc3d9e0db7?pvs=74";
  }, []);

  return <>{helmet}</>;
}
