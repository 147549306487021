export function LandingHeading() {
  return (
    <>
      <div className="flex-col text-white">
        {/* Loophole Catchphrase */}
        <div className="flex items-center justify-center text-[35px] sm:text-[45px] lg:text-[45px] xl:text-[50px] 2xl:text-[60px] font-extrabold">
          Hop on the Loop
        </div>
        <div className="flex flex-row items-center justify-center text-[12px] sm:text-[15px] lg:text-[15px] xl:text-[18px] 2xl:text-[20px] font-extrabold gap-2 py-4">
          <div>Multiplayer loops.</div>
          <div>Live experiences.</div>
          <div>Servers you love.</div>
        </div>
      </div>
    </>
  );
}
