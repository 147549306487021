import { FaHashtag } from "react-icons/fa";
import { FaClapperboard } from "react-icons/fa6";
import { HiSquare2Stack } from "react-icons/hi2";
import { IoMdChatbubbles } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { LandingPageAssetPath } from "../../../../../types/common/assets";
import { DemoFeatureBox, DemoPage, LandingFeatureBox } from "./demo-page";

/**
 * Returns component containing loophole feed page demo and preview.
 * Preview contains feature boxes that describe the page.
 */
export function LoopholeFeedDemo() {
  // Feature to chat in a server, share in a server + talk to others who have similar interests
  // People icon
  const serverFeatureBox: DemoFeatureBox = {
    content: (
      <LandingFeatureBox
        heading="Engaging Servers"
        content="Find interest groups!"
        logoElement={<HiSquare2Stack size={"45px"} color="white" />}
        alignVisual="RIGHT"
      />
    ),
    featureBoxTailwind: `
      origin: top-1/4 right-0 
      hide smaller than 360px: hidden xs:block
      mobile: w-[85px] -translate-x-[190px] (200px different from 250px due to phone image size change) -translate-y-[110px]
      sm:w-[180px] sm:-translate-x-[250px] sm:-translate-y-[125px] 
      md:w-[235px] md:-translate-x-[250px] md:-translate-y-[105px]
      lg:w-[180px] lg:-translate-x-[250px] lg:-translate-y-[125px] 
      xl:w-[235px] xl:-translate-x-[250px] xl:-translate-y-[105px]
      2xl:w-[235px] 2xl:-translate-x-[300px] 2xl:-translate-y-[120px]
    `,
  };

  // Separate feed: clean separation between text and short videos for easier sharing and viewing
  // Video clapped icon
  const separateFeedFeature: DemoFeatureBox = {
    content: (
      <LandingFeatureBox
        heading="Separate Feed"
        content="Express your humor in GCs and DMs!"
        alignVisual="RIGHT"
        logoElement={<FaClapperboard size={"30px"} color="white" />}
      />
    ),
    featureBoxTailwind: `
      origin: top-1/2 right-0
      hide smaller than 360px: hidden xs:block
      mobile: w-[85px] -translate-x-[190px] -translate-y-[40px] 
      sm:w-[160px] sm:-translate-x-[250px] sm:-translate-y-[50px]
      md:w-[240px] md:-translate-x-[250px] md:-translate-y-[30px]
      lg:w-[160px] lg:-translate-x-[250px] lg:-translate-y-[50px]
      xl:w-[240px] xl:-translate-x-[250px] xl:-translate-y-[30px]
      2xl:w-[240px] 2xl:-translate-x-[300px] 2xl:-translate-y-[40px]
    `,
  };

  // Loophole feed chat
  // Chat icon
  const feedChatFeature: DemoFeatureBox = {
    content: (
      <LandingFeatureBox
        heading="Channels"
        content="Engage in server chats!"
        alignVisual="LEFT"
        logoElement={<IoMdChatbubbles size={"35px"} color="white" />}
      />
    ),
    featureBoxTailwind: `
      origin: top-1/4 left-0
      hide smaller than 360px: hidden xs:block
      mobile: w-[85px] translate-x-[190px] -translate-y-[100px]
      sm:w-[175px] sm:translate-x-[250px] sm:-translate-y-[125px]
      md:w-[235px] md:translate-x-[250px] md:-translate-y-[115px]
      lg:w-[175px] lg:translate-x-[250px] lg:-translate-y-[125px]
      xl:w-[240px] xl:translate-x-[250px] xl:-translate-y-[108px]
      2xl:w-[240px] 2xl:translate-x-[300px] 2xl:-translate-y-[125px]
    `,
  };

  // TODO:
  // List gamification features (streaks, connection score, looplive + share compatibility, etc) [Issue is that looplive feature won't be here for a while]
  // Flame icon
  //   const gamifiedFeature = (
  //     <LandingFeatureBox
  //       heading="Streaks"
  //       content=""
  //       imagePath={LandingPageAssetPath.LoopholeLogoTransparent}
  //     />
  //   );

  // Looptags feature
  // Tag icon
  const looptagFeatureBox: DemoFeatureBox = {
    content: (
      <LandingFeatureBox
        heading="Looptags"
        content="Explore loopholes using hashtags!"
        logoElement={<FaHashtag size={"30px"} color="white" />}
        alignVisual="RIGHT"
      />
    ),
    featureBoxTailwind: `
      origin: bottom-1/2 right-0
      hide smaller than 360px: hidden xs:block
      mobile: w-[85px] -translate-x-[190px] translate-y-[188px]
      sm:w-[150px] sm:-translate-x-[250px] sm:translate-y-[240px]
      md:w-[210px] md:-translate-x-[250px] md:translate-y-[222px]
      lg:w-[150px] lg:-translate-x-[250px] lg:translate-y-[240px]
      xl:w-[210px] xl:-translate-x-[250px] xl:translate-y-[225px]
      2xl:w-[210px] 2xl:-translate-x-[300px] 2xl:translate-y-[260px]

    `,
  };

  // Privacy "Go incognito to share your thoughts"
  // Note: Potential negative is that this isn't exactly so obviously good as there are externalities
  const privacyFeatureBox: DemoFeatureBox = {
    content: (
      <LandingFeatureBox
        heading="Explore Privately"
        content="Comment in incognito!"
        logoElement={<IoEye size={"35px"} color="white" />}
        alignVisual="LEFT"
      />
    ),
    featureBoxTailwind: `
      origin: top-1/2 left-0
      hide smaller than 360px: hidden xs:block
      mobile: w-[85px] translate-x-[190px] -translate-y-[20px]
      sm:w-[170px] sm:translate-x-[250px] sm:-translate-y-[13px]
      md:w-[235px] md:translate-x-[250px] md:-translate-y-[-6px]
      lg:w-[170px] lg:translate-x-[250px] lg:-translate-y-[13px]
      xl:w-[235px] xl:translate-x-[250px] xl:-translate-y-[-6px]
      2xl:w-[235px] 2xl:translate-x-[300px] 2xl:translate-y-[12px]
    `,
  };

  const featureBoxes = [
    privacyFeatureBox,
    looptagFeatureBox,
    serverFeatureBox,
    feedChatFeature,
    separateFeedFeature,
  ];

  // TODO: Test width and height
  return (
    <DemoPage
      pageAltName="Loophole Feed Page Demo"
      demoPageImagePath={LandingPageAssetPath.FeaturedPagesLoopholeSpecific}
      imageTailwind="w-[200px] sm:w-[250px] 2xl:w-[300px]"
      featureBoxes={featureBoxes}
    />
  );
}
