import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { logLinkClick } from "../../libs/loophole-apis/src/logging";
import {
  LandingPageAssetPath,
  getLandingPageAssetPath,
} from "../types/common/assets";
import { LoopholeDownloadRoute } from "../types/routes/external-routes";
import { LandingFooter } from "./components/common/landing-footer";
import { LandingHeader } from "./components/common/landing-header";
import {
  LandingSocialLink,
  createLandingSocialLinks,
} from "./components/common/landing-social-link";
import { LandingFeatures } from "./components/home/landing-features/landing-features";
import { LandingHeading } from "./components/home/landing-heading";
import { seoStructuredData } from "./seo-scripts/seo-structured-data";

// TODO: Add background animation?
export function LandingHome() {
  const location = useLocation();

  const helmet = (
    <Helmet>
      <title>Loophole - Hop on the Loop</title>
      <meta
        name="description"
        content="Share short videos with friends and find interest communities on Loophole - Hop on the Loop"
      />
      <script type="application/ld+json">
        {JSON.stringify(seoStructuredData)}
      </script>
    </Helmet>
  );

  // Social links
  const socialLinksProps = createLandingSocialLinks("text-white text-[35px]");
  const socialLinks = (
    <div className="sm:py-[5px] w-fit flex flex-row gap-[70px]">
      {socialLinksProps.map((socialLinkProps, index) => (
        <div key={index}>{LandingSocialLink(socialLinkProps)}</div>
      ))}
    </div>
  );

  // Heading + Download/Sign-up + Download
  const headingAndDownload = (
    <div className="flex flex-col">
      <div className="flex-1 flex flex-col align-middle justify-center items-center py-[100px] lg:py-[40px] min-h-screen lg:min-h-[500px]">
        <LandingHeading />
        <a
          href={LoopholeDownloadRoute.AppStore}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logLinkClick("App Store Download", location.pathname)}
          className="w-[300px] mt-[50px] mb-[20px]"
        >
          <img
            src={getLandingPageAssetPath(LandingPageAssetPath.AppStoreDownload)}
            alt={"iOS App Store Download"}
          ></img>
        </a>
        {socialLinks}
      </div>
    </div>
  );

  // Features
  const featuresSection = (
    // lg:w-[750px] 2xl:w-[900px]: Triple the width of the demo picture png (to include space for the feature boxes)
    <div className="flex justify-center align-middle items-center pb-[200px] lg:w-[600px] xl:w-[800px] lg:py-[50px]">
      <LandingFeatures />
    </div>
  );

  // Body content
  const bodyContent = (
    <div className="flex-1 flex flex-col w-screen lg:flex-row lg:justify-evenly">
      {headingAndDownload}
      {featuresSection}
    </div>
  );

  // NOTE: Breakpoints (width):
  // Larger Desktop: 2xl [1536px] -> (Increase content size, row body)
  // Desktop: xl [1280px] -> (Increase content size, row body)
  // Laptop: lg [1024px] <-> xl (Increase content size, row body, col feature box)
  // Tablet: md [768px] <-> lg (Default content, col body, row feature box)
  // Larger Mobile: sm [640px] -> md (Default content, col body, col footer, col feature box)
  // Mobile: -> sm (Default content, col body, col footer, no feature box)
  return (
    <div className="flex flex-col font-raleway items-center min-h-screen w-screen">
      {helmet}
      {/* <LandingCookieBanner /> */}
      <LandingHeader />

      {bodyContent}

      <div className="bg-opacity-50 border-t border-white shadow-lg border-opacity-40">
        <LandingFooter />
      </div>
    </div>
  );
}
