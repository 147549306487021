import { ReactNode } from "react";

/**
 * Wrapper component to append a triangle to a react element
 */
export function LandingAppendTriangle({
  parent,
  dimensions: { triangleWidth, triangleHeight },
  cssBackgroundColor,
  direction,
}: {
  parent: ReactNode;
  dimensions: { triangleWidth: number; triangleHeight: number };
  cssBackgroundColor?: string;
  direction: "LEFT" | "RIGHT" | "UP" | "DOWN";
}) {
  const triangleStyles = {
    LEFT: {
      cssStyle: {
        border: "solid",
        borderRight: `solid ${cssBackgroundColor} ${triangleHeight}px`, // Same as relative parent container height, Same color as menu background
        borderTop: `solid transparent ${triangleWidth}px`, // Half of triangle container box width
        borderBottom: `solid transparent ${triangleWidth}px`, // Half of triangle container box width
        borderLeft: "transparent",
        transform: `translate(-${triangleHeight}px , -${triangleWidth}px)`,
      },
      tailwindStyle: `top-1/2 left-0`,
    },
    RIGHT: {
      cssStyle: {
        border: "solid",
        borderLeft: `solid ${cssBackgroundColor} ${triangleHeight}px`, // Same as relative parent container height, Same color as menu background
        borderTop: `solid transparent ${triangleWidth}px`, // Half of triangle container box width
        borderBottom: `solid transparent ${triangleWidth}px`, // Half of triangle container box width
        borderRight: "transparent",
        transform: `translate(${triangleHeight}px , -${triangleWidth}px)`,
      },
      tailwindStyle: `top-1/2 right-0`,
    },
    DOWN: {
      cssStyle: {
        border: "solid",
        borderTop: `solid ${cssBackgroundColor} ${triangleHeight}px`, // Same as relative parent container height, Same color as menu background
        borderLeft: `solid transparent ${triangleWidth}px`, // Half of triangle container box width
        borderRight: `solid transparent ${triangleWidth}px`, // Half of triangle container box width
        borderBottom: "transparent",
        transform: `translate(-${triangleHeight}px)`,
      },
      tailwindStyle: "left-1/2",
    },
    UP: {
      cssStyle: {
        border: "solid",
        borderBottom: `solid ${cssBackgroundColor} ${triangleHeight}px`, // Same as relative parent container height, Same color as menu background
        borderLeft: `solid transparent ${triangleWidth}px`, // Half of triangle container box width
        borderRight: `solid transparent ${triangleWidth}px`, // Half of triangle container box width
        borderTop: "transparent",
        transform: `translate(-${triangleHeight}px`,
      },
      tailwindStyle: "left-1/2",
    },
  };

  const { tailwindStyle, cssStyle } = triangleStyles[direction];
  const triangle = (
    <div
      className={`absolute h-[0px] w-[0px] ${tailwindStyle}`}
      style={cssStyle}
    ></div>
  );

  return (
    <div className="relative">
      <div className="relative">{parent}</div>
      {triangle}
    </div>
  );
}
