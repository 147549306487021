/**
 * The enumeration of social media external routes
 */
export enum LoopholeSocialRoute {
  Instagram = "https://www.instagram.com/loopholeplatforms/",
  Discord = "https://discord.gg/x6aSbJTj7V", // Invite link
  Twitter = "https://twitter.com/Loophole_lol",
}

/**
 * The enumeration of download links
 */
export enum LoopholeDownloadRoute {
  AppStore = "https://apps.apple.com/us/app/loophole-hop-on-the-loop/id6482848488",
}
