import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { seoStructuredData } from "./seo-scripts/seo-structured-data";

export function LandingTerms() {
  const helmet = (
    <Helmet>
      <title>Terms of Service | Loophole</title>
      <meta name="description" content="Loophole Platforms Terms of Service" />
      <script type="application/ld+json">
        {JSON.stringify(seoStructuredData)}
      </script>
    </Helmet>
  );
  // TODO: Remove relocation when making custom web page
  // Redirect to a different website
  useEffect(() => {
    window.location.href =
      "https://loopholeplatforms.notion.site/Loophole-Platforms-Terms-of-Service-158c0b7605504bd5bb93b98a66e76b83";
  }, []);

  return <>{helmet}</>;
}
