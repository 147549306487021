import { Route, Routes } from "react-router-dom";
import {
  LandingAbout,
  LandingCommunity,
  LandingContact,
  LandingCookies,
  LandingHome,
  LandingPrivacy,
  LandingSafety,
  LandingTerms,
} from "../landing-page";
import { LoopholeBrowserRoute } from "../types/routes/main-routes";

// TODO: Figure out how to add route for faq component (which should be part of the parent landing about component)
// TODO: Replace with proper element components after adding custom pages
/**
 * The routes component for the app.
 */
export function AppRoutes() {
  return (
    <Routes>
      <Route
        path={LoopholeBrowserRoute.LandingHome}
        element={<LandingHome />}
      />
      <Route
        path={LoopholeBrowserRoute.LandingAbout}
        element={<LandingAbout />}
      />
      <Route
        path={LoopholeBrowserRoute.LandingPrivacy}
        element={<LandingPrivacy />}
      />
      <Route
        path={LoopholeBrowserRoute.LandingContact}
        element={<LandingContact />}
      />
      <Route
        path={LoopholeBrowserRoute.LandingCookie}
        element={<LandingCookies />}
      />
      <Route
        path={LoopholeBrowserRoute.LandingSafety}
        element={<LandingSafety />}
      />
      <Route
        path={LoopholeBrowserRoute.LandingCommunity}
        element={<LandingCommunity />}
      />
      <Route
        path={LoopholeBrowserRoute.LandingTerms}
        element={<LandingTerms />}
      />
    </Routes>
  );
}
