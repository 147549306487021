import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../../../../firebase/firebase";

export function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    // Logs the pageview using Firebase Analytics.
    logEvent(analytics, "page_view", {
      page_path: location.pathname,
    });
  }, [location.pathname]);
}

export function logLinkClick(title: string, location: string) {
  logEvent(analytics, `${title}_link_clicked`, { location });
}
