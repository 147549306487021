import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { seoStructuredData } from "./seo-scripts/seo-structured-data";

export function LandingAbout() {
  const helmet = (
    <Helmet>
      <title>About | Loophole</title>
      <meta name="description" content="Our focus and values." />
      <script type="application/ld+json">
        {JSON.stringify(seoStructuredData)}
      </script>
    </Helmet>
  );

  // TODO: Remove relocation when making custom web page
  // Redirect to a different website
  useEffect(() => {
    window.location.href =
      "https://loopholeplatforms.notion.site/About-Loophole-6cc99c1a2aad4af4a85a18f88823d937?pvs=74";
  }, []);

  return <>{helmet}</>;
}
